.the-legend {
    border-style: none;
    border-width: 0;
    font-size: 1.5rem;
    line-height: 20px;
    margin-bottom: 0;
    width: auto;
    padding: 0px 10px;
    border: 1px solid #e0e0e0;
}
.the-fieldset {
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin-bottom: 20px;
}
body{ padding-bottom: 70px; }
