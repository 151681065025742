body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.form-signin-heading{
  color: #fff;
}
.login-container{
  padding-top: 55px;
  margin: 0 auto;
}
.border-green{
  border-color: #2cc185;
  border-width: 2px;
}
.green-black{
  color: #2cc185;
  background-color: #363636;

}
@media (min-width: 768px) {
  .login-container{
    width: 800px;
    padding-top: 55px;
    margin: 0 auto;
  }
}
.login-btn{
  color: #fff;
  background-color: #2cc185;
  border-color: #2cc185;
}
.login-div-left {
  padding: 10px 10px 10px;
  background-color: #2cc185;
}
@media (min-width: 768px) {
  .login-div-left {
    padding: 50px 30px 40px;
  }
}
.login-div-right {
  padding: 10px 10px 10px;
  background-color: #363636;
}
@media (min-width: 768px) {
  .login-div-right {
    padding: 50px 30px 40px;
  }
}
.card-footer-kitchen{
  background-color: #28a745;
  color: white;
}
.card-footer-wardrobe{
  background-color: #007bff;
  color: white;
}

.badgebox
{
  opacity: 0;
}

.badgebox + .badge
{
  /* Move the check mark away when unchecked */
  text-indent: -999999px;
  /* Makes the badge's width stay the same checked and unchecked */
  width: 27px;
}

.badgebox:focus + .badge
{
  /* Set something to make the badge looks focused */
  /* This really depends on the application, in my case it was: */

  /* Adding a light border */
  box-shadow: inset 0px 0px 5px;
  /* Taking the difference out of the padding */
}

.badgebox:checked + .badge
{
  /* Move the check mark back when checked */
  text-indent: 0;
}
.mutfak-table-column {
  background-color:#6eca83
}
.acilan-table-column {
  background-color:#4599f3
}
.surme-table-column {
  background-color:#17a2b8
}
