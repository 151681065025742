.Sidebar {
    min-height: 100vh;
    transition: margin .4s ease-in-out;
    z-index: 1;
    border-right: 1px solid #008e27;
}

.Sidebar-Brand {
    color: #038f35!important;
    display: block;
    font-size: 1.50rem;
    font-weight: 500;
    padding: 0.5rem;
}
.Space-Right {
    padding-right: 5px;
}
.bg-Ciddi
{
    background-color: #75ab99;
    color: white;
    padding: 0;
}
.bg-Ciddi a {
    color: #222222;
    padding: 0;
}
.Sidebar-Link {
    color: #fff;
    padding: 1rem 0.5rem;
    position: relative;
    font-size: 1.2em;
}
.Sidebar-Link a{
    color: white;
}
.Sidebar-Link a.active{
    background-color: #188839;
    color: white;
    padding: 10px;
    border-radius: 10px;
}
.green-active .active a,
.nav-pills .nav-link.active {
    background-color: #188839;
    color: #222222;
}
